module.exports = {
  title: "Kolmården",
  author: "Rebel and Bird",
  description: "Kolmården",
  siteUrl: process.env.SITE_URL ? process.env.SITE_URL : "https://www.kolmarden.com",
  locales: [
    { locale: "sv", default: true },
    { locale: "en", default: false },
    { locale: "fi", default: false },
  ],
  timeZone: "Europe/Stockholm",
  timeUtcOffsetSummerTime: "+02:00",
  timeUtcOffsetNormalTime: "+01:00",
  defaultLocale: "sv",
};
