module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K2LT7X4","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kolmården","short_name":"Kolmården","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icons":[{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../plugins/gatsby-plugin-graphql-to-json/gatsby-browser.js'),
      options: {"plugins":[],"windowStoreProperty":"___stores","stores":[{"name":"restaurant","query":"{\n            allContentfulRestaurantBlock(filter: { title: { ne: null }}) {\n              nodes {\n                id\n                contentful_id\n                node_locale\n                sortIndex\n                title\n                preamble {\n                    preamble\n                }\n                description {\n                    childMarkdownRemark {\n                        html\n                    }\n                }\n                shortDescriptionInList\n                imageInList { \n                    file { url }\n                }\n                label\n                tags {\n                    title\n                }\n                restaurantPageLink {\n                    slug\n                }\n              }\n            }\n          }","groupBy":"node_locale"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/boka-rum-kalender":["X-Frame-Options: SAMEORIGIN"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
