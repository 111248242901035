class ThemeHelper {
  setTheme(themeName) {
    this.theme = themeName;
  }

  getTheme() {
    return this.theme;
  }
}

const singelton = new ThemeHelper();
export default singelton;
