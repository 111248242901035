exports.onClientEntry = async (args, pluginOptions) => {
  const { stores, windowStoreProperty = "___stores" } = pluginOptions;

  window[windowStoreProperty] = window[windowStoreProperty] || {};

  if (!(stores instanceof Array && stores.length > 0)) {
    return;
  }

  stores.forEach((store) => {
    window[windowStoreProperty][store.name] = {
      getStore: () => fetch(`${__PATH_PREFIX__}/${store.name}-store.json`).then((response) => response.json()),
    };
  });

  // const storeFetchPromises = stores.map(store => {
  //     return fetch(`${__PATH_PREFIX__}/${store.name}-store.json`)
  //         .catch(err => console.err(`Store fetch failed: ${err}`));
  // });

  // window[windowStoreProperty].isFetching = true;

  // Promise.all(storeFetchPromises)
  //     .then(response => {
  //         return Promise.all(response.map(r => r.json()));
  //     })
  //     .then(response => {
  //         response.forEach(store => {
  //             window[windowStoreProperty][store.name] = {
  //                 data: store.data,
  //                 groupBy: store.groupBy
  //             };
  //         });
  //     })
  //     .catch(err => {
  //         console.error('Error while fetching stores for plugin gatsby-plugin-graphql-to-json', err);
  //     })
  //     .finally(() => {
  //         window[windowStoreProperty].isFetching = false;
  //     })
};
