import { useContext, Component, createContext } from "react";
import { NotificationCenter } from "@parksandresorts/core";
import { NotificationContextConsumer, NotificationContextProvider } from "@parksandresorts/core";

const GlobalContext = createContext({
  isTopNavigationHidden: false,
  isFooterHidden: false,
  isViewportScrollable: true,
  subNavHidden: false,
  isMainVisible: true,
  showMenu: false,
  showCart: false,
  showSearch: false,
  isMenuAnimating: false,
  isMenuAnimationDone: false,
  isSearchInTopSection: true,
  setValue: function (value, callback) {},
});
const GlobalContextConsumer = GlobalContext.Consumer;

class GlobalContextProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTopNavigationHidden: false,
      isFooterHidden: false,
      isViewportScrollable: true,
      subNavHidden: false,
      isMainVisible: true,
      showMenu: false,
      showCart: false,
      showSearch: false,
      isMenuAnimating: false,
      isMenuAnimationDone: false,
      isSearchInTopSection: true,
      setValue: this.setValue.bind(this),
    };
  }

  setValue(value, callback) {
    if (value instanceof Object && value.hasOwnProperty("isMainVisible") && value.isMainVisible === false) {
      this.lastScrollTopPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      this.updateScrollTopOnShowMain = true;
    }

    this.setState(value, () => {
      if (this.updateScrollTopOnShowMain && this.state.isMainVisible) {
        this.updateScrollTopOnShowMain = false;
        document.body.scrollTop = this.lastScrollTopPosition;
        document.body.parentNode.scrollTop = this.lastScrollTopPosition;
      }

      if (value instanceof Object && value.hasOwnProperty("isMainVisible") && value.isMainVisible === false) {
        document.body.scrollTop = 0;
        document.body.parentNode.scrollTop = 0;
      }

      if (typeof callback === "function") {
        callback();
      }
    });
  }

  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        <div id="root" className="theme-kolmarden foundation common component body-m kolmarden shared">
          <NotificationContextProvider>
            <NotificationContextConsumer>
              {(state) => (
                <NotificationCenter
                  removeNotification={state.removeNotification}
                  notifications={state.notifications}
                  setValue={this.state.setValue}
                />
              )}
            </NotificationContextConsumer>
          </NotificationContextProvider>
          {this.props.children}
        </div>
      </GlobalContext.Provider>
    );
  }
}

function useGlobalState() {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalContextProvider");
  }
  return context;
}

export default GlobalContext;
export { GlobalContext, GlobalContextConsumer, GlobalContextProvider, useGlobalState };
