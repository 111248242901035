import { isArray, extract, DateHelper, createDate } from "@parksandresorts/core";

class JetPassHelper {
  /**
   * @param {Array} jetPassWithApiFormat
   * @returns {Array} An array with jetpassformat that fits jetpass-selection (jetpass registration flow)
   */
  formatToSelectionFormat(jetPassWithProductFormat) {
    if (!jetPassWithProductFormat) {
      return null;
    }

    if (isArray(jetPassWithProductFormat)) {
      const selectionFormatedJetpass = jetPassWithProductFormat.map((jetPass) => {
        return this.createSelectionObjectFromProductRowFormat(jetPass);
      });
      return selectionFormatedJetpass;
    }

    return this.createSelectionObjectFromProductRowFormat(jetPassWithProductFormat);
  }

  sortByNumberOfJetpasses = (a, b) => {
    if (a.numberOfJetpasses === b.numberOfJetpasses) {
      return 0;
    }

    return a.numberOfJetpasses > b.numberOfJetpasses ? -1 : 1;
  };

  createSelectionObjectFromProductRowFormat(productRowFormatObject) {
    const startDate = extract(productRowFormatObject, "validity.notBefore");
    const startTime = extract(productRowFormatObject, "validity.notBeforeTime");

    const endDate = extract(productRowFormatObject, "validity.notAfter");
    const endTime = extract(productRowFormatObject, "validity.notAfterTime");

    return {
      attractionId: productRowFormatObject.plcId,
      timeSlotFrom: startDate + "T" + startTime + ":00",
      timeSlotTo: endDate + "T" + endTime + ":00",
    };
  }

  formatFromOrderRowFormaToAPIFormat(rowFormatItem) {
    const dateString = extract(rowFormatItem, "validity.notBefore");
    const timeString = extract(rowFormatItem, "validity.notBeforeTime");
    const dateObj = createDate(dateString + "T" + timeString);
    const numericStartTime = DateHelper.getNumericTimeFromDate(dateObj);

    return {
      plcId: rowFormatItem.plcId,
      timeFrom: numericStartTime,
      barcode: rowFormatItem.barcode,
    };
  }

  formatFromSelectionToAPIFormat(selectionItem) {
    return {
      timeSlotId: selectionItem.id,
      attractionId: selectionItem.attractionId,
      timeSlotFrom: selectionItem.startDateTime,
      timeSlotTo: selectionItem.endDateTime,
    };
  }

  convertQueuePassesToAPIReady(oldJetPass = [], newFromFlow = []) {
    let availableBarcodes = oldJetPass.map((jetPass) => jetPass.barcode);

    const newPasses = [];
    const editedPasses = [];
    const remainingPasses = [];

    for (const newPass of newFromFlow) {
      const oldPass = oldJetPass.find((pass) => pass.plcId === newPass.plcId);
      if (oldPass) {
        availableBarcodes = availableBarcodes.filter((barcode) => barcode !== oldPass.barcode);
        // we remove the barcode from available barcodes since we are going to use it at this pass or not change it at all
        // if time is new, we need to post it to the API, otherwise its the same past and we don't post it.
        const oldApiFormatItem = this.formatFromOrderRowFormaToAPIFormat(oldPass);

        if (newPass.timeFrom !== oldApiFormatItem.timeFrom) {
          editedPasses.push({
            plcId: oldPass.plcId,
            timeFrom: newPass.timeFrom,
            barcode: oldPass.barcode,
          });
        }
      } else {
        //if ther is no old pass with the same PLC, we need to give it a barcode from the pool of available barcodes.
        remainingPasses.push(newPass);
      }
    }

    // we check all the passes that didn't have a matching PLC id and assign them barcodes
    // if we run out of barcodes, it is a new pass to be posted and not a rebooked one, they don't require barcode
    for (let i = 0; i < remainingPasses.length; i++) {
      const remainingPass = remainingPasses[i];
      if (availableBarcodes.length > 0) {
        editedPasses.push({
          plcId: remainingPass.plcId,
          timeFrom: remainingPass.timeFrom,
          barcode: availableBarcodes.shift(),
        });
      } else {
        newPasses.push({
          plcId: remainingPass.plcId,
          timeFrom: remainingPass.timeFrom,
        });
      }
    }

    const data = [...newPasses, ...editedPasses];

    return data;
  }
}

const helper = new JetPassHelper();
export default helper;
