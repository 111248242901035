class LocationHelper {
  navigateBack() {
    if (typeof window !== "undefined") {
      window.history.go(-1);
    }
  }

  addHashToUrlWithoutReload(valuesArray) {
    if (!(typeof window !== "undefined" && window.history && window.history.replaceState)) {
      return;
    }

    if (!(valuesArray instanceof Array && valuesArray.length > 0)) {
      return window.history.replaceState({}, `${window.title}`, window.location.pathname);
    }

    const delimiter = "&";
    const assignValue = "=";
    const valueStringsArray = valuesArray.map((item) => item.key + assignValue + item.value);
    const allValues = valueStringsArray.join(delimiter);

    return window.history.replaceState({}, `${window.title}`, `#${allValues}`);
  }

  getDecodedHashParams() {
    const hashParams = {};

    if (typeof window !== "undefined") {
      if (window.location && window.location.hash) {
        window.location.hash.substr(1).split`&`.forEach((item) => {
          let [k, v] = item.split`=`;
          v = v && decodeURIComponent(v);
          (hashParams[k] = hashParams[k] || []).push(v);
        });
      }
      return hashParams;
    }
    return [];
  }

  getQueryParams() {
    var pageParams = {};

    if (typeof window !== "undefined") {
      if (window.location.search) {
        window.location.search.substr(1).split`&`.forEach((item) => {
          let [k, v] = item.split`=`;
          v = v && decodeURIComponent(v);
          (pageParams[k] = pageParams[k] || []).push(v);
        });
      }

      return pageParams;
    }

    return [];
  }

  getChannelFromQuery() {
    var pageParams = this.getQueryParams();

    return pageParams instanceof Object &&
      pageParams.channel instanceof Array &&
      pageParams.channel.length &&
      pageParams.channel[0]
      ? pageParams.channel[0]
      : "";
  }
}

const singleton = new LocationHelper();
export default singleton;
